<template>
  <a-drawer title="添加" width="50%" :visible="visible" :body-style="{ paddingBottom: '80px' }" @close="onClose">
    <a-spin :spinning="loading">
      <a-form :form="form" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="运费模板名称">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '该项必填' }],
              },
            ]"
            placeholder="请输入运费模板名称"
          />
        </a-form-item>

        <a-form-item label="运费模板备注">
          <a-input v-decorator="['comment']" placeholder="请输入运费模板备注" />
        </a-form-item>

        <a-form-item label="">
          <a-button type="primary" size="small" @click="showModal = true"> 添加可配送区域 </a-button>
        </a-form-item>
        <a-form-item label="" :wrapperCol="{ span: 24 }">
          <a-table :pagination="false" :columns="columns" :data-source="freightRules" tableLayout="auto" bordered>
            <span slot="price" slot-scope="text, record">
              <a-input
                style="color: #888"
                placeholder="请输入邮费"
                :defaultValue="text"
                allow-clear
                @change="inputChange($event, record)"
              />
            </span>
            <span slot="action" slot-scope="text, record">
              <a @click="updateFreightRule(record)">修改</a>
              <a-divider type="vertical" />
              <a
                style="color: #f5222d"
                @click="freightRules = freightRules.filter((freightRule) => freightRule.id !== record.id)"
                >删除</a
              >
            </span>
          </a-table>
        </a-form-item>
        <!--        <a-form-item label="是否默认" help="是否设为默认运费模板">-->
        <!--          <a-switch v-decorator="['isDefault', { valuePropName: 'checked', initialValue: true }]" />-->
        <!--        </a-form-item>-->
      </a-form>
      <div style="display: flex; justify-content: center; width: 100%; padding: 20px 16px">
        <a-button :style="{ marginRight: '8px' }" @click="onClose"> 关闭 </a-button>
        <a-button type="primary" :disabled="disabledSubmit" @click="submit"> 确定 </a-button>
      </div>
    </a-spin>

    <!--   省份选择 -->
    <a-modal v-model="showModal" width="50%" title="选择省份" @cancel="cancel">
      <a-checkbox-group v-model="checkedProvinceIds" name="checkboxgroup" :options="provinces">
        <span slot="label" slot-scope="{ districtName }" style="color: #8e8f92">{{ districtName }}</span>
      </a-checkbox-group>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="submit" type="primary" @click="confirm" :disabled="checkedProvinceIds.length === 0">
            确定
          </a-button>
        </div>
      </template>
    </a-modal>
  </a-drawer>
</template>

<script>
import { allProvince, addMallFreight, editMallFreight, mallFreightDetail } from '@/api/modular/mallLiving/orderAdmin'

const columns = [
  {
    title: '可配送区域',
    dataIndex: 'provinceNames',
  },

  {
    title: '邮费（元）',
    dataIndex: 'price',
    width: '200px',
    scopedSlots: { customRender: 'price' },
  },

  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  name: 'custom-drawer',
  props: {
    visible: Boolean,
    default: false,
  },
  data() {
    return {
      columns,
      form: this.$form.createForm(this),
      showModal: false,
      loading: false,
      editActiveId: '',
      checkedProvinceIds: [],
      provinces: [],
      freightRules: [],
      detail: null,
    }
  },
  computed: {
    disabledSubmit() {
      return this.freightRules.length === 0 || this.freightRules.some((freightRule) => freightRule.price === '')
    },
  },
  watch: {
    freightRules(newVal) {
      console.log('newVal', newVal)
      newVal.forEach((freightRule) => {
        const provinceIds = freightRule.provinceIds.split(',')
        this.provinces = this.provinces.map((province) => {
          if (provinceIds.includes(province.districtId)) {
            return {
              ...province,
              disabled: true,
            }
          } else {
            return province
          }
        })
      })
    },
    editActiveId(newVal) {
      if (newVal) {
        this.provinces = this.provinces.map((province) => {
          if (this.checkedProvinceIds.includes(province.districtId)) {
            return {
              ...province,
              disabled: false,
            }
          } else {
            return province
          }
        })
      }
    },
  },
  created() {
    this.getAllProvince()
  },
  methods: {
    inputChange(event, record) {
      console.log('record', event, record)
      this.freightRules = this.freightRules.map((freightRule) => {
        if (freightRule.id === record.id) {
          return { ...freightRule, price: event.target.value }
        } else {
          return freightRule
        }
      })
    },
    updateFreightRule(record) {
      this.editActiveId = record.id
      this.checkedProvinceIds = record.provinceIds.split(',')
      this.showModal = true
    },
    confirm() {
      let provinceNames = ''
      const provinceIds = this.checkedProvinceIds.join(',')
      this.provinces.forEach((province) => {
        if (this.checkedProvinceIds.includes(province.districtId)) {
          provinceNames += province.districtName + ','
        }
      })
      provinceNames = provinceNames.substring(0, provinceNames.length - 1)
      if (this.editActiveId) {
        this.freightRules = this.freightRules.map((freightRule) => {
          if (freightRule.id === this.editActiveId) {
            return Object.assign({}, freightRule, { provinceNames, provinceIds })
          } else {
            return freightRule
          }
        })
        this.editActiveId = ''
      } else {
        this.freightRules.push({
          id: this.uuidv4(),
          provinceIds,
          provinceNames: provinceNames,
          price: '',
        })
      }

      this.cancel()
    },

    cancel() {
      this.showModal = false
      this.checkedProvinceIds = []
    },
    onClose() {
      this.provinces = this.provinces.map((province) => {
        return {
          ...province,
          disabled: false,
        }
      })
      this.detail = null
      this.freightRules = []
      this.form.resetFields()
      this.$emit('update:visible', false)
    },
    submit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const freightRulesList = this.freightRules.map((freightRule) => {
            const { id, ...restObj } = freightRule
            return restObj
          })
          console.info('success', values)
          const { name, comment, isDefault } = values
          const params = {
            name,
            comment,
            freightRulesList,
            isDefault: isDefault ? 2 : 1,
          }

          if (!this.detail) {
            this.add(params)
          } else {
            this.update({ ...params, id: this.detail.id })
          }
        }
      })
    },
    add(params) {
      addMallFreight(params)
        .then((res) => {
          if (res.success) {
            this.$message.success('添加运费模板成功')
            this.onClose()
            setTimeout(() => {
              this.$parent.getList()
            }, 10)
          }
        })
        .catch((error) => {
          this.$message.error(error.message || '添加运费模板失败')
        })
    },
    update(params) {
      editMallFreight(params)
        .then((res) => {
          if (res.success) {
            this.$message.success('编辑运费模板成功')
            this.onClose()
            setTimeout(() => {
              this.$parent.getList()
            }, 10)
          }
        })
        .catch((error) => {
          this.$message.error(error.message || '编辑运费模板失败')
        })
    },
    getAllProvince() {
      allProvince().then((res) => {
        console.log('省份', res)
        if (res.success) {
          if (res.data && Array.isArray(res.data)) {
            this.provinces = res.data.map((province) => ({ ...province, value: province.districtId }))
          }
        }
      })
    },
    getDetail(id) {
      this.loading = true
      mallFreightDetail({ id })
        .then((res) => {
          console.log('詳情', res)
          if (res.success) {
            const data = res.data

            this.form.setFieldsValue({
              name: data.name,
            })
            this.form.setFieldsValue({
              comment: data.comment,
            })
            this.form.setFieldsValue({
              isDefault: +data.isDefault === 2,
            })
            this.detail = data
            if (data.freightRulesList && Array.isArray(data.freightRulesList)) {
              this.freightRules = data.freightRulesList.map((item) => ({
                id: item.id,
                provinceIds: item.provinceIds,
                provinceNames: item.provinceNames,
                price: item.price,
              }))
            }
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 20)
        })
    },
  },
}
</script>

<style scoped>
/deep/ .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
/deep/ .ant-checkbox-group-item {
  margin: 0 0 10px;
  width: 25%;
}
</style>
